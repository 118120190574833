import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaStethoscope, FaPrescriptionBottle, FaTruck, FaFacebook, FaYoutube, FaInstagram, FaBuilding, FaHandshake, FaHospital } from 'react-icons/fa';
import logo from '../assets/healthcarelogo.jpg'; // Correct import path and filename
import './Navbar.css';
import { MdLocalPharmacy } from 'react-icons/md';
import QRModal from '../components/QRModal'; 

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    
  };

  const handleUnderConstruction = (e) => {
    e.preventDefault(); // Prevents any default action
    alert("The site is under construction.");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

   
  const closeModal = () => {
    setIsModalOpen(false);
  };

   

  return (
    <>
      {/* Topbar */}
      <div className="topbar">
        <div className="topbar-content">
          <span>Phone: +880 1713335282, 09606501155 | Email: dghealthcare@dipon.com.bd</span>
          <div className="topbar-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebook />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              <FaYoutube />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="DG Healthcare" className="navbar-logo-img" />
          <span className="navbar-byline">By Dipon Group</span>
         
      {/* QR Modal Component */}
      <QRModal showModal={isModalOpen} handleClose={closeModal} />
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
         
        </div>
        <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>About Us</Link></li>
          <li className="services-dropdown">
            <span className="dropdown-button">Services</span>
            <ul className="dropdown-menu">
              <li><Link to="#" onClick={openModal}><FaStethoscope style={{ marginRight: '8px' }} /> DG eMedical App</Link></li>
              <li><Link to="#" onClick={handleUnderConstruction}><FaStethoscope style={{ marginRight: '8px' }} /> DG eMedical website</Link></li>
              <li><Link to="http://ec2-65-2-33-13.ap-south-1.compute.amazonaws.com/login" onClick={toggleMenu}><MdLocalPharmacy style={{ marginRight: '8px' }} /> DG ePharma</Link></li>
              <li><Link to="#" onClick={handleUnderConstruction}><FaHandshake style={{ marginRight: '8px' }} /> DG Med B2B</Link></li>
              <li><Link to="/dg-edelivery" onClick={toggleMenu}><FaTruck style={{ marginRight: '8px' }} /> DG eDelivery</Link></li>  
            </ul>
          </li>
          <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
